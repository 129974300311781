<template>
  <div>
    <v-row class="page-break">
      <template v-for="color in colors">
        <v-col
          :key="`img-${color}`"
          cols="3"
          class="mb-2">
          <img
            :src="colorImage(color)"
            class="packing-list-img-preview">
        </v-col>
        <v-col
          :key="`info-${color}`"
          cols="4"
          class="mb-2">
          <div>
            <p
              v-if="showProductName"
              class="mb-0">
              {{ showProductName }}
            </p>
            <p class="mb-0">
              {{ productId }}
            </p>
            <p class="mb-0">
              {{ sellPrice | showFullPriceFormat() }} บาท
            </p>
          </div>
        </v-col>
        <v-col
          :key="`table-${color}`"
          cols="5"
          class="mb-2">
          <div>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th
                    class="field-label text-center"
                    width="100px">
                    Size
                  </th>
                  <th
                    class="field-label text-center"
                    width="110px">
                    Color
                  </th>
                  <th class="field-label text-center">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sku, skuIndex) in filterSkusByColor(color)"
                  :key="`productSku-${colors}-${skuIndex}`">
                  <td
                    class="text-center text-uppercase"
                    width="100px">
                    {{ sku.size }}
                  </td>
                  <td
                    class="text-center text-uppercase"
                    width="110px">
                    {{ sku.color }}
                  </td>
                  <td class="text-center">
                    <!-- {{ sku.quantity }} -->
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { prototypeData } from '@/assets/js/DefaultPrototypeData'

export default {
  props: {
    prototype: {
      type: Object,
      default: () => prototypeData
    }
  },
  computed: {
    productSkus () {
      return this.prototype?.order?.productSkus || []
    },
    colors () {
      return [...new Set(this.productSkus.map((sku) => sku.color))]
    },
    fittingImage () {
      return this.prototype?.image || ''
    },
    productId () {
      return this.prototype?.order?.productId || ''
    },
    sellPrice () {
      return this.prototype?.order?.sellPrice || ''
    },
    showProductName () {
      const name = this.productSkus[0]?.productSellsuki?.name || ''
      const arrayName = name.split(' - ')
      return arrayName[0]
    }
  },
  methods: {
    filterSkusByColor (color) {
      return this.productSkus.filter((sku) => sku.color === color)
    },
    colorImage (color) {
      const colorProducts = this.filterSkusByColor(color)
      return this.fittingImage || colorProducts[0]?.productSellsuki?.photos[0]
    }
  }
}
</script>

<style>

</style>
