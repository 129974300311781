<template>
  <v-main>
    <div
      size="A4"
      class="page-a4 container elevation-5">
      <packing-list-for-export
        v-for="prototype in prototypes"
        :key="prototype.id"
        :prototype="prototype" />
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import PackingListForExport from '../components/PackingListForExport.vue'

export default {
  components: {
    PackingListForExport
  },
  computed: {
    ...mapGetters({
      prototypes: 'Prototypes/prototypes'
    })
  },
  mounted () {
    window.print()
  }
}
</script>

<style lang="scss">
#app {
  div[size="A4"] {
    display: block;
    width: 210mm;
    min-height: 297mm;
    margin: 10px auto;
    padding: calc(12.7mm - 24px);
    background-color: #ffffff;
    word-wrap: break-word;
    page-break-after: always;
    .row {
      display: block;
      width: 100%;
    }
    .row + .row {
      margin-top: 0;
    }
    .col {
      display: inline-block;
      flex-basis: unset;
      -webkit-box-flex: unset;
      flex-grow: unset;
      position: relative;
      vertical-align: middle;
      float: none !important;
    }
  }
  .page-break-after {
    display: none;
  }
}
@media print {
  #app {
    .page-break {
      page-break-before: always;
      page-break-inside: avoid;
    }
    .page-break-after {
      display: block;
      page-break-after: always;
    }
  }
}
</style>
